import { useMemo } from 'react';
import clsx from 'clsx';
import { ColorsMap } from '../../../sci-ui-components/utils/colors';
import { Collectible } from '../../../sci-ui-components/types/collectible';
import { CollectiblesChartStats, CollectibleChartStats } from '../../../sci-ui-components/types/chartData';
import Table from '../../../sci-ui-components/Table/Table';
import ChartedCollectibleIndicator from '../../../sci-ui-components/collectibles/ChartedCollectibleIndicator/ChartedCollectibleIndicator';
import DefaultTitle from '../../../sci-ui-components/Table/presets/DefaultTitle';
import CollectibleTableCell from '../../../sci-ui-components/collectibles/CollectibleTableCell/CollectibleTableCell';
import createStatColumn from '../../../sci-ui-components/Table/presets/StatColumn';
import { useBreakpoint } from '../../../sci-ui-components/hooks/useBreakpoint';
import { Breakpoint } from '../../../sci-ui-components/styles/breakpoints';
import splitArrayIntoColumns from '../../../sci-ui-components/utils/splitArrayIntoColumns';
import { collectibleNames, CollectibleType } from '../../collectibles/constants';
import { makeAverageSaleLabel, getDateRangeLabel } from '../Charts/utils';
import { useAlternateGradesForCollectible } from '../../grades/useAvailableGrades';

import classes from './ChartsLegend.module.scss';
import { useCollectiblesOld } from 'features/collectibles/useCollectibleOld';
import { useCommonCollectiblesActions } from 'features/collectibles/useCommonCollectiblesActions';
import useFormatStatValue from 'hooks/useFormatStatValue';
import { DateRangeDaysCount } from 'sci-ui-components/utils/date';
import { formatDate } from 'sci-ui-components/utils/date';

export interface ChartsLegendProps {
  className?: string;
  isLoading: boolean;
  collectibles: Collectible[];
  collectibleIds: number[];
  onCollectibleHover?: (collectibleId: number | null) => void;
  colors: ColorsMap<number>;
  collectibleStats: CollectiblesChartStats | null;
  hiddenCollectiblesIds?: number[];
  onToggleCollectibleVisibility?: (collectibleId: number) => void;
  collectibleType: CollectibleType;
  chartedDays: DateRangeDaysCount;
  onReplaceCollectible: (fromCollectibleId: number, toCollectibleId: number) => void;
}

const statWidth = 'minmax(92px, auto)';

export default function ChartsLegend({ className, collectibleIds, collectibleType, ...other }: ChartsLegendProps) {
  const breakpoint = useBreakpoint(true, 'xs');

  const { collectibles } = useCollectiblesOld({
    collectibleType,
    collectibleIds,
  });

  const groups = useMemo(() => splitCollectibles(collectibles, breakpoint!), [collectibles, breakpoint]);

  return (
    <div className={clsx(classes.columns, className)}>
      {groups.map((group, index) =>
        group.length > 0 ? (
          <ChartsLegendTable
            className={classes.column}
            key={index}
            {...other}
            collectibleType={collectibleType}
            collectibles={group}
            collectibleIds={collectibleIds}
          />
        ) : null
      )}
    </div>
  );
}

function ChartsLegendTable({
  className,
  collectibles,
  collectibleStats,
  colors,
  hiddenCollectiblesIds = [],
  isLoading,
  onCollectibleHover,
  onToggleCollectibleVisibility,
  collectibleType,
  chartedDays,
  onReplaceCollectible,
}: ChartsLegendProps) {
  const actions = useCommonCollectiblesActions({ collectibleType });
  const { formatStatValue, selectedCurrencySymbol } = useFormatStatValue();

  return (
    <Table<Collectible, RowData, number>
      className={clsx(classes.root, className)}
      items={collectibles}
      getRowData={(collectible) => getRowData({ collectible, collectibleStats, colors, hiddenCollectiblesIds })}
      getRowClassName={({ isHidden }) => (isHidden ? classes.hidden : undefined)}
      columns={[
        {
          key: 'indicator',
          renderData: ({ color, isHidden, collectible }) => (
            <ChartedCollectibleIndicator
              className={classes.indicator}
              color={color}
              isHidden={isHidden}
              onToggleHidden={
                onToggleCollectibleVisibility ? () => onToggleCollectibleVisibility(collectible.id) : undefined
              }
            />
          ),
          width: '28px',
        },
        {
          key: 'collectible',
          renderTitle: (props) => (
            <DefaultTitle {...props} justify="start">
              {collectibleNames[collectibleType].shortSingular}
            </DefaultTitle>
          ),
          renderData: ({ collectible }) => (
            <LegendCollectibleTableCell
              collectible={collectible}
              onPopulationCountClick={(collectibleId: number) => actions.chartPopulationCount(collectibleId)}
              onReplaceCollectible={onReplaceCollectible}
              chartedCollectibleIds={collectibles.map(({ id }) => id)}
            />
          ),
          width: 'minmax(220px, auto)',
        },
        createStatColumn({
          key: 'end-avg',
          title: 'Last Sale',
          getValue: ({ stats }) => stats?.endAvgSalePrice,
          getSubText: ({ stats }) => (stats?.lastSaleDate ? formatDate(stats?.lastSaleDate) : null),
          type: 'price',
          width: statWidth,
          formatStatValue,
        }),
        createStatColumn({
          key: 'change-$',
          title: `Change ${selectedCurrencySymbol}`,
          getValue: ({ stats }) => stats?.salePriceChange,
          type: 'price',
          highlightChange: true,
          width: statWidth,
          formatStatValue,
        }),
        createStatColumn({
          key: 'change-%',
          title: 'Change %',
          getValue: ({ stats }) => stats?.salePriceChangePercentage,
          type: 'percentage',
          highlightChange: true,
          width: statWidth,
          formatStatValue,
        }),
        createStatColumn({
          key: 'sales-count',
          title: 'Sales Count',
          getValue: ({ stats }) => stats?.salesCount,
          type: 'count',
          width: statWidth,
          formatStatValue,
        }),
        createStatColumn({
          key: 'avg',
          title: makeAverageSaleLabel(chartedDays),
          renderTitle: (props) => (
            <DefaultTitle justify="center" {...props}>
              <>
                Avg <br /> {getDateRangeLabel(chartedDays)}
              </>
            </DefaultTitle>
          ),
          getValue: ({ stats }) => stats?.avgSalePrice,
          type: 'price',
          width: statWidth,
          formatStatValue,
        }),
      ]}
      getRowKey={({ collectible }) => collectible.id}
      isLoading={isLoading}
      onRowHover={onCollectibleHover}
    />
  );
}

interface RowData {
  collectible: Collectible;
  stats: CollectibleChartStats | null;
  color?: string;
  isHidden: boolean;
}

function getRowData({
  collectible,
  hiddenCollectiblesIds,
  colors,
  collectibleStats,
}: {
  collectible: Collectible;
  collectibleStats: CollectiblesChartStats | null;
  colors: ColorsMap<number>;
  hiddenCollectiblesIds: number[];
}): RowData {
  return {
    collectible,
    color: colors.get(collectible.id),
    stats: collectibleStats ? collectibleStats[collectible.id] : null,
    isHidden: hiddenCollectiblesIds.includes(collectible.id),
  };
}

function splitCollectibles(collectibles: Collectible[], breakpoint: Breakpoint): Collectible[][] {
  if (['xs', 'sm', 'md'].includes(breakpoint)) {
    return [collectibles];
  }
  return splitArrayIntoColumns(collectibles, 2);
}

function LegendCollectibleTableCell({
  collectible,
  onReplaceCollectible,
  onPopulationCountClick,
  chartedCollectibleIds,
}: {
  collectible: Collectible;
  onReplaceCollectible: (fromCollectibleId: number, toCollectibleId: number) => void;
  onPopulationCountClick: (collectibleId: number) => void;
  chartedCollectibleIds: number[];
}) {
  const { alternateGrades = [] } = useAlternateGradesForCollectible({
    oldCollectible: collectible,
    excludeCollectibleIds: chartedCollectibleIds,
  });
  return (
    <CollectibleTableCell
      collectibleType={collectible.collectibleType}
      collectible={collectible}
      alternateGrades={alternateGrades}
      size="small"
      onPopulationCountClick={() => onPopulationCountClick(collectible.id)}
      onGradeChange={({ collectibleId }) => onReplaceCollectible(collectible.id, collectibleId)}
    />
  );
}
