import { ReactNode, useEffect, useState } from 'react';
import ModalDialog from '../ModalDialog/ModalDialog';
import classes from './ConfirmationDialog.module.scss';
import { ConfirmationDialogInfoProps } from './types';
import LoadingSpinner from 'sci-ui-components/LoadingSpinner/LoadingSpinner';
import FormFieldsRow from 'sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import FieldLabelBox from 'sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import TextField from 'sci-ui-components/forms/TextField/TextField';

export type ConfirmationDialogProps = Omit<ConfirmationDialogInfoProps, 'onConfirm'> & {
  isOpen: boolean;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
};

export default function ConfirmationDialog({
  isOpen,
  onCancel,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  title = 'Are you sure?',
  description = null,
  requireTextInput,
  error,
}: ConfirmationDialogProps & {
  error?: ReactNode | null;
}) {
  const [isConfirming, setIsConfirming] = useState(false);
  const [text, setText] = useState(''); // TODO
  useEffect(() => setText(''), [requireTextInput]);
  const canConfirm = !requireTextInput || text === requireTextInput;

  return (
    <ModalDialog
      open={isOpen}
      title={title}
      okText={isConfirming ? <LoadingButton /> : confirmText}
      cancelText={cancelText}
      onOk={async () => {
        setIsConfirming(true);
        await onConfirm();
        setIsConfirming(false);
      }}
      onCancel={onCancel}
      variant="secondary"
      okButtonProps={{
        autoFocus: true,
        disabled: isConfirming || !canConfirm,
      }}
    >
      {description}
      {!!requireTextInput && (
        <FormFieldsRow>
          <FieldLabelBox
            label={
              <>
                Please type <strong>&quot;{requireTextInput}&quot;</strong> to confirm
              </>
            }
          >
            <TextField value={text} onChange={setText} />
          </FieldLabelBox>
        </FormFieldsRow>
      )}
      {!!error && <div>{error}</div>}
    </ModalDialog>
  );
}

function LoadingButton() {
  return (
    <div className={classes.loading}>
      <LoadingSpinner className={classes.spinner} size="large" />
    </div>
  );
}
