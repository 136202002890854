import { ReactNode, useEffect, useState } from 'react';
import { withErrorBoundary } from '../../sci-ui-components/ErrorBoundary/ErrorBoundary';
import ConfirmationDialog from './ConfirmationDialog';
import { registerDialog, ConfirmationDialogProviderProps } from './index';

type State = Partial<ConfirmationDialogProviderProps> & {
  isOpen: boolean;
};

export default withErrorBoundary(function ConfirmationDialogProvider({ id }: { id: string }) {
  const [
    { isOpen, cancelText, confirmText, description, resolve, title, onConfirm, onCancel, requireTextInput },
    setState,
  ] = useState<State>({
    isOpen: false,
  });
  const [error, setError] = useState<ReactNode | null>(null);

  useEffect(
    () =>
      registerDialog(id, (props) => {
        setState({
          isOpen: true,
          ...props,
        });
        setError(null);
      }),
    [id]
  );

  const handleClose = () => setState((s) => ({ ...s, isOpen: false }));

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={title}
      description={description}
      requireTextInput={requireTextInput}
      confirmText={confirmText}
      cancelText={cancelText}
      onConfirm={async () => {
        const result = await onConfirm?.();
        if (result?.renderError) {
          setError(result.renderError());
        } else {
          resolve?.(true);
          handleClose();
        }
      }}
      onCancel={() => {
        onCancel?.();
        resolve?.(false);
        handleClose();
      }}
      error={error}
    />
  );
});
