import { CSSProperties, MouseEvent, MouseEventHandler, useMemo, useState } from 'react';
import NextLink from 'next/link';
import Image from 'next/legacy/image';
import clsx from 'clsx';
import { faBars, faCaretDown, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleCollapsible from '../../SimpleCollapsible/SimpleCollapsible';
import IconButton from '../../buttons/IconButton/IconButton';
import { CollectibleType, collectibleTypes } from '../../types/collectibleType';
import { NavigationMenuItem } from '../types';

import classes from './MobileMenu.module.scss';
import { collectibleNames } from 'sci-ui-components/collectibles/constants';

type MobileMenuItem<TRoute extends string> = Omit<NavigationMenuItem<TRoute>, 'route' | 'icon' | 'children'> & {
  route?: NavigationMenuItem<TRoute>['route'];
  icon?: NavigationMenuItem<TRoute>['icon'];
  onClick?: () => void;
  children?: MobileMenuItem<TRoute>[];
};

const preventLink: MouseEventHandler<HTMLAnchorElement> = (e) => {
  e.preventDefault();
};

export default function MobileMenu<TRoute extends string>({
  className,
  menuItems,
  logoHref,
  collapsedGroupsByDefault = false,
  collectibleType,
  onCollectibleTypeChange,
}: {
  className?: string;
  menuItems: NavigationMenuItem<TRoute>[];
  logoHref: string;
  collapsedGroupsByDefault?: boolean;
  collectibleType?: CollectibleType;
  onCollectibleTypeChange?: (collectibleType: CollectibleType) => void;
}) {
  const [style, setStyle] = useState<CSSProperties | undefined>(undefined);
  const collectibleSelectorGroupItem = useMemo<MobileMenuItem<TRoute> | null>(() => {
    if (!onCollectibleTypeChange || !collectibleType) {
      return null;
    }
    return {
      id: 'collectible-selector',
      isActive: false,
      isDisabled: false,
      label: `COLLECTIBLE: ${collectibleNames[collectibleType].shortPlural}`,
      isSubMenuItem: false,
      children: collectibleTypes.map<MobileMenuItem<TRoute>>((ct) => ({
        id: `collectible-selector-${ct}`,
        isActive: ct === collectibleType,
        isDisabled: false,
        label: collectibleNames[ct].plural,
        isSubMenuItem: true,
        onClick: () => onCollectibleTypeChange(ct),
      })),
    };
  }, [collectibleType, onCollectibleTypeChange]);
  const handleOpenClick = (e: MouseEvent<HTMLButtonElement>) => {
    const { currentTarget } = e;
    if (!currentTarget) {
      return;
    }
    const { top, left } = currentTarget.getBoundingClientRect();

    setStyle({
      top,
      left,
      marginLeft: -left,
      marginTop: -top,
    });
  };

  const handleClose = () => {
    setStyle(undefined);
  };

  const isOpen = !!style;

  return (
    <>
      <div className={className}>
        <IconButton faIcon={faBars} onClick={handleOpenClick} title="Open menu" size="large" color="default" />
      </div>
      <div
        className={clsx(classes.backdrop, {
          [classes.backdropOpen]: isOpen,
        })}
        role="button"
        onClick={handleClose}
      />
      <div
        className={clsx(classes.container, {
          [classes.open]: isOpen,
        })}
        style={style}
      >
        <div className={classes.menuHeader}>
          <NextLink href={logoHref} className={classes.logo} onClick={handleClose}>
            <Image src="/images/logo.png" width="40" height="40" alt="Market Movers" />
          </NextLink>
          <h2 className={classes.title}>Market Movers</h2>
          <IconButton
            faIcon={faXmark}
            onClick={handleClose}
            title="Close menu"
            className={classes.closeButton}
            size="large"
            color="lightGrey"
          />
        </div>
        <ul className={classes.list}>
          {!!collectibleSelectorGroupItem && (
            <li key={collectibleSelectorGroupItem.id} className={classes.listItem}>
              <MenuItemsGroup
                groupItem={collectibleSelectorGroupItem}
                onClick={handleClose}
                isExpandedByDefault={false}
              />
            </li>
          )}
          <li key="separator" className={classes.separator} />
          {menuItems.map((item) => {
            return (
              <li key={item.id} className={classes.listItem}>
                {item.children?.length ? (
                  <MenuItemsGroup
                    groupItem={item}
                    onClick={handleClose}
                    isExpandedByDefault={!collapsedGroupsByDefault}
                  />
                ) : (
                  <MenuItem item={item} onClick={handleClose} />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

function MenuItemsGroup<TRoute extends string>({
  groupItem,
  onClick,
  isExpandedByDefault,
}: {
  groupItem: MobileMenuItem<TRoute>;
  onClick: () => void;
  isExpandedByDefault: boolean;
}) {
  const { isDisabled, isActive, children } = groupItem;
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  return (
    <div className={classes.itemsGroup}>
      <button
        onClick={() => setIsExpanded((v) => !v)}
        className={clsx(classes.item, {
          [classes.disabled]: isDisabled,
          [classes.active]: isActive,
        })}
      >
        <MenuLabel item={groupItem} />
        <FontAwesomeIcon
          className={clsx(classes.groupIndicator, {
            [classes.groupIndicatorOpen]: isExpanded,
          })}
          icon={faCaretDown}
        />
      </button>
      <SimpleCollapsible isExpanded={isExpanded}>
        <ul className={classes.list}>
          {children?.map((subItem) => {
            return (
              <li key={subItem.id} className={classes.listItem}>
                <MenuItem
                  item={subItem}
                  onClick={() => {
                    onClick();
                    setIsExpanded(isExpandedByDefault);
                  }}
                />
              </li>
            );
          })}
        </ul>
      </SimpleCollapsible>
    </div>
  );
}

function MenuItem<TRoute extends string>({ item, onClick }: { item: MobileMenuItem<TRoute>; onClick: () => void }) {
  const { route, isDisabled, isActive, isSubMenuItem, Wrapper } = item;
  const classNames = clsx(classes.item, {
    [classes.disabled]: isDisabled,
    [classes.active]: isActive,
    [classes.subItem]: isSubMenuItem,
  });
  if (Wrapper) {
    return (
      <Wrapper href={route}>
        <div className={classNames}>
          <MenuLabel item={item} />
        </div>
      </Wrapper>
    );
  }
  if (route) {
    return (
      <NextLink href={route} className={classNames} onClick={isDisabled ? preventLink : onClick}>
        <MenuLabel item={item} />
      </NextLink>
    );
  }
  return (
    <button
      className={classNames}
      onClick={
        isDisabled
          ? undefined
          : () => {
              item.onClick?.();
              onClick();
            }
      }
    >
      <MenuLabel item={item} />
    </button>
  );
}

function MenuLabel<TRoute extends string>({ item }: { item: MobileMenuItem<TRoute> }) {
  const { icon, label, isDisabled, disabledReason } = item;
  return (
    <>
      {!!icon && <FontAwesomeIcon className={classes.icon} icon={icon} />}
      <div className={classes.labelWrapper}>
        <span className={classes.label}>{Array.isArray(label) ? label.join(' ') : label}</span>
        {isDisabled && !!disabledReason && <span className={classes.itemNote}>{disabledReason}</span>}
      </div>
    </>
  );
}
